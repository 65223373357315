import { NextRouter } from 'next/router';

import { Options } from 'holded/modules/cms/domain/components/banner';
import {
  CmsRepository,
  excludePages,
  GlobalData,
  LanguageCode,
  LocalizationSlug,
  Page,
  SpecialSiteType,
} from 'holded/modules/cms/domain/page';

const getPagesSlugs = async (repository: CmsRepository) => {
  const pages = await repository.getPages();
  const pathFalse = {
    params: {
      slug: false,
    },
  };

  if (!pages?.data) {
    return [pathFalse];
  }

  return pages.data.map((page) => {
    if (page.attributes) {
      if (!excludePages.includes(page.attributes.slug ?? '')) {
        const locale = page.attributes.locale === 'en' ? '' : `${page.attributes.locale}/`;
        const slug = page.attributes.slug ?? '';
        return {
          params: {
            slug: `${locale}${slug}`.split('/'),
          },
        };
      }
    }

    return pathFalse;
  });
};

const getDefaultLocale = async (repository: CmsRepository) => {
  const locales = await repository.getLocales();
  return locales?.find((locale) => locale.isDefault)?.code ?? 'en';
};

const getLocales = async (repository: CmsRepository) => {
  const locales = await repository.getLocales();
  return locales?.map((item) => item.code);
};

const getCurrentLocale =
  (repository: CmsRepository) =>
  async (slugs: string[]): Promise<LanguageCode> => {
    const locales = await getLocales(repository);
    const defaultLocale = await getDefaultLocale(repository);
    if (slugs?.length > 0) {
      const locale = locales?.find((locale: string) => locale == slugs[0]);
      if (locale) return locale;
    }
    return defaultLocale;
  };

const getGlobalData =
  (repository: CmsRepository) =>
  async (locale: LanguageCode): Promise<GlobalData> => {
    const global = await repository.getGlobal(locale);
    const header = await repository.getHeader(locale);
    const footer = await repository.getFooter(locale);

    return {
      global: global?.data ?? null,
      header: header?.data ?? null,
      footer: footer?.data ?? null,
    };
  };

const getPageData = (repository: CmsRepository) => async (slug: string[], locale: LanguageCode) => {
  const page = await repository.getPage(slug, locale);
  if (!page?.data || page.data.length < 1) {
    return null;
  }

  //Select first slug match page
  return page.data[0];
};

const getSpecialSiteDetailSlug = (repository: CmsRepository) => async (slug: string[], locale: LanguageCode) => {
  const page = await repository.getPage(slug, locale);

  return page.data[0].attributes?.slugLang;
};

const getIntegrationSlug =
  (repository: CmsRepository) =>
  async (slug: string): Promise<string | undefined> => {
    const slugs = slug.split('/');
    const integrationSlug = slugs.at(-1);
    const integrations = await repository.getIntegrations();
    const integration = integrations.data.find((i) => i.attributes?.slug === integrationSlug);
    const integrationAlt = integrations.data.find(
      (i) =>
        i.attributes.slugLang === integration?.attributes.slugLang &&
        i.attributes.locale !== integration?.attributes.locale,
    );

    if (!integration?.attributes.slugLang) {
      return integration?.attributes.slug;
    }

    if (integration && integrationAlt?.attributes.slug) {
      return integrationAlt?.attributes.slug;
    }

    return integration?.attributes.slug;
  };

const getSuccessCaseSlug =
  (repository: CmsRepository) =>
  async (slug: string): Promise<string | undefined> => {
    const slugs = slug.split('/');
    const successCaseSlug = slugs.at(-1);
    const successCases = await repository.getSuccessCases();
    const successCase = successCases.data.find((successCase) => successCase.attributes?.slug === successCaseSlug);

    const successCaseAlt = successCases.data.find(
      (s) =>
        s.attributes.slugLang == successCase?.attributes.slugLang &&
        s.attributes.locale !== successCase?.attributes.locale &&
        s.attributes.slug == successCase?.attributes.slug,
    );

    return successCaseAlt?.attributes.slug;
  };

const getWebinarSlug =
  (repository: CmsRepository) =>
  async (slug: string): Promise<string | undefined> => {
    const isRegister = slug.split('/').pop() === 'register';
    const webinarSlug = isRegister ? slug.split('/').at(-2) : slug.split('/').pop();

    const webinars = await repository.getWebinars();

    const webinar = webinars.data.find((webinar) => webinar.attributes?.slug === webinarSlug);

    const webinarAlt = webinars.data.find(
      (w) =>
        w.attributes?.slugLang === webinar?.attributes.slugLang && w.attributes.locale !== webinar?.attributes.locale,
    );

    if (isRegister) {
      return webinarAlt?.attributes?.slug ? `${webinarAlt?.attributes?.slug}/register` : undefined;
    }

    return webinarAlt?.attributes.slug;
  };

const getAlternatePath =
  (repository: CmsRepository) =>
  async (slug: string): Promise<string | undefined> => {
    const integrationSlug = await getIntegrationSlug(repository)(slug);

    if (integrationSlug) {
      return integrationSlug;
    }

    const successCaseSlug = await getSuccessCaseSlug(repository)(slug);
    if (successCaseSlug) {
      return successCaseSlug;
    }

    const webinarSlug = await getWebinarSlug(repository)(slug);
    if (webinarSlug) {
      return webinarSlug;
    }

    return undefined;
  };

const getLocalizationSlugs =
  (repository: CmsRepository) =>
  async ({
    specialSite,
    slug,
    locales,
    pageData,
    currentLocale,
    defaultLocale,
  }: {
    specialSite: SpecialSiteType | null;
    slug: string;
    locales: LanguageCode[];
    pageData: Page;
    currentLocale: LanguageCode;
    defaultLocale: LanguageCode;
  }): Promise<LocalizationSlug[]> => {
    if (specialSite) {
      const alternatePath = await getAlternatePath(repository)(slug);

      return locales.reduce((acc: LocalizationSlug[], locale) => {
        const localePath = locale === defaultLocale ? '' : `/${locale}`;
        if (locale === currentLocale) {
          acc.push({
            locale,
            slug: `${localePath}${slug}`,
          });
          return acc;
        }

        if (alternatePath) {
          acc.push({
            locale,
            slug: `${localePath}/${specialSite?.slug[locale]}/${alternatePath}`,
          });
        }

        return acc;
      }, []);
    }

    return locales.reduce((acc: LocalizationSlug[], locale) => {
      const localePath = locale === defaultLocale ? '' : `/${locale}`;
      if (locale === currentLocale) {
        acc.push({
          locale,
          slug: `${localePath}${slug === '/' && localePath !== '' ? '' : slug}`,
        });

        return acc;
      }

      const localization = pageData.attributes?.localizations?.data.find((loc) => loc.attributes?.locale === locale);

      if (localization) {
        const slug = localization.attributes?.slug ? `/${localization.attributes?.slug}` : '/';
        acc.push({
          locale,
          slug: `${localePath}${slug === '/' && localePath !== '' ? '' : slug}`,
        });
      }

      return acc;
    }, []);
  };

const getTopBarOptions = ({ options, router }: { options?: Options; router: NextRouter }) =>
  options?.allPages
    ? !options?.omitPages?.data?.some((page) => page.attributes?.slug && router.asPath.includes(page.attributes.slug))
    : options?.addPages?.data?.some((page) => page.attributes?.slug && router.asPath.includes(page.attributes.slug));

export {
  getIntegrationSlug,
  getPagesSlugs,
  getDefaultLocale,
  getLocales,
  getCurrentLocale,
  getGlobalData,
  getPageData,
  getSpecialSiteDetailSlug,
  getAlternatePath,
  getLocalizationSlugs,
  getTopBarOptions,
};
